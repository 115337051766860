import { Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
import {
    memo,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import Slider from 'react-slick';
import { ThemeContext } from 'styled-components';
import Cookies from 'universal-cookie';
import {
    BannerImageAPIInterface,
    BannerImageI,
} from '../../Interface/HomepageCollectionsInterface';
import { COLORS } from '../../constants/colors';
import { GWMobileWrapper, GWWebWrapper } from '../../styles/window.style';
import { MaskIcon } from '../Icons/Icons';

import {
    CarouselItemContainer,
    HeroGridCarouselContainer,
    HeroGridCarouselWrapper,
    NextArrowWrapper,
    PreviousArrowWrapper,
} from './HeroGridCarousel.styles';
import HeroGridCarouselItem from './HeroGridCarouselItem';

interface IArrow {
  onClick?: () => void;
  disabled?: boolean;
}

// eslint-disable-next-line react/display-name
const NextArrow = memo((props: IArrow) => {
  const theme = useContext(ThemeContext);
  return (
    <NextArrowWrapper onClick={props.onClick}>
      <MaskIcon
        url="/icons/arrow-right-chevron-24.svg"
        color={theme.palette.text.t1}
        width="48px"
        height="48px"
      />
    </NextArrowWrapper>
  );
});

// eslint-disable-next-line react/display-name
const PreviousArrow = memo((props: IArrow) => {
  const theme = useContext(ThemeContext);
  return (
    <PreviousArrowWrapper onClick={props.onClick}>
      {/* <ArrowBgWrapper /> */}
      <MaskIcon
        url="/icons/arrow-right-chevron-24.svg"
        color={theme.palette.text.t1}
        width="48px"
        height="48px"
      />
    </PreviousArrowWrapper>
  );
});

interface Props {
  bannerImages: BannerImageAPIInterface | null;
}

const cookies = new Cookies();

const HeroGridCarouselComp = (props: Props) => {
  const sliderRefDesktop = useRef<any>();
  const router = useRouter();
  const [showBFBanner, setShowBFBanner] = useState<boolean>(false);
  const sliderRefMobile = useRef<any>();

  const handleOnClick = useCallback((index: number) => {
    sliderRefDesktop.current.slickGoTo(index);
    sliderRefMobile.current.slickGoTo(index);
  }, []);

  const customDots = useCallback(
    (dots: any) => (
      <div style={{ bottom: '5px', display: 'flex', justifyContent: 'center' }}>
        <ul
          style={{
            background: '#00000099',
            width: 'fit-content',
            borderRadius: '24px',
            padding: '0px 2px',
          }}
        >
          {dots.map((item: any, index: number) => (
            <li
              onClick={() => handleOnClick(index)}
              key={index}
              style={{
                height: '10px',
                width: '10px',
                borderRadius: '50%',
                margin:'0 4px',
                opacity: item.props.className === 'slick-active' ? 0.8 : 0.25,
                background: COLORS.white,
              }}
            />
          ))}
        </ul>
      </div>
    ),
    [handleOnClick]
  );

  useEffect(() => {
    const acq = cookies.get('drif_acq');

    if (!!acq || !!router.query.utm_source) {
      setShowBFBanner(true);
    } else {
      setShowBFBanner(false);
    }
  }, [router]);

  const settings = {
    adaptiveHeight: true,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    fade: false,
    centerMode: false,
    // centerPadding: '180px',
    dots: true,
    appendDots: customDots,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          // centerPadding: '20px',
          arrows: true,
          centerMode: false,
          swipeToSlide: true,
          nextArrow: <NextArrow />,
          prevArrow: <PreviousArrow />,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          // centerPadding: '72px',
          dots: true,
          nextArrow: <NextArrow />,
          prevArrow: <PreviousArrow />,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          // centerPadding: '72px',
          dots: true,
          nextArrow: <NextArrow />,
          prevArrow: <PreviousArrow />,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 1728,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          // centerPadding: '102px',
          dots: true,
          nextArrow: <NextArrow />,
          prevArrow: <PreviousArrow />,
          swipeToSlide: true,
        },
      },
    ],
  };

  return (
    <HeroGridCarouselWrapper>
      <HeroGridCarouselContainer>
        <GWWebWrapper>
          <Slider {...settings} ref={sliderRefDesktop}>
            {props?.bannerImages
              ? props?.bannerImages?.desktop?.map(
                  (data: BannerImageI, index: number) => (
                    <HeroGridCarouselItem
                      key={index}
                      data={data}
                      index={index}
                    />
                  )
                )
              : [1, 2, 3].map((count: number) => {
                  return (
                    <CarouselItemContainer key={count}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ borderRadius: '8px' }}
                        animation="wave"
                        height="100%"
                        width="100%"
                      />
                    </CarouselItemContainer>
                  );
                })}
          </Slider>
        </GWWebWrapper>
        <GWMobileWrapper>
          <Slider {...settings} ref={sliderRefMobile}>
            {props?.bannerImages
              ? props?.bannerImages?.mobile?.map(
                  (data: BannerImageI, index: number) => (
                    <HeroGridCarouselItem
                      key={index}
                      data={data}
                      index={index}
                    />
                  )
                )
              : [1, 2, 3].map((count: number) => {
                  return (
                    <CarouselItemContainer key={count}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ borderRadius: '8px' }}
                        animation="wave"
                        height="100%"
                        width="100%"
                      />
                    </CarouselItemContainer>
                  );
                })}
          </Slider>
        </GWMobileWrapper>
      </HeroGridCarouselContainer>
    </HeroGridCarouselWrapper>
  );
};

export default HeroGridCarouselComp;
