// Android
// Battle.net
// Bethesda
// EA Origin
// Epic Games
// GOG COM
// Kinguin
// Kinguin balance
// NCSoft
// Nintendo
// Other
// PlayStation 3
// PlayStation 4
// PlayStation 5
// PlayStation Vita
// Rockstar Games
// Steam
// Uplay
// XBOX 360
// XBOX ONE
// XBOX Series X|S
export {};

export const getPlatformPageLinkByPlatform = (platform: string): string => {
  switch (platform) {
    case 'PSN':
      return '/store/psn';
    case 'Steam':
      return '/store/steam';
    case 'Nintendo':
      return '/store/nintendo';
    case 'GOG COM':
      return '/store/gog-com';
    case 'Ubisoft Connect':
      return '/store/ubisoft-connect';
    case 'Xbox Live':
      return '/store/xbox';
    case 'Roblox':
      return '/store/roblox';
    case 'Mojang':
      return '/store/mojang';
    case 'NCSoft':
      return '/store/ncsoft';
    case 'Rockstar Games':
      return '/store/rockstar-games';
    default:
      return `/store?platform[]=${platform.replace(' ','+')}&page=1`;
  }
};

export const homePagePlatformsData = [
  {
    title: 'EA Play',
    iconUrl: '/images/platform-logo-full/ea-play-logo-full.svg',
    bgColor: '#FF4747',
    url: '/store/platform/ea-play',
    query: {},
  },
  {
    title: 'Steam',
    iconUrl: '/images/platform-logo-full/steam-logo-full.svg',
    bgColor: 'linear-gradient(102.71deg, #0B8BB8 0%, #113166 100%)',
    url: '/store/platform/steam',
    query: {},
  },
  {
    title: 'Xbox',
    iconUrl: '/images/platform-logo-full/xbox-logo-full.svg',
    bgColor: '#107C10',
    url: '/store',
    query: { 'platform[]': ['Xbox Live'] },
  },
  {
    title: 'PSN',
    iconUrl: '/images/platform-logo-full/psn-logo-full.svg',
    bgColor: '#0070D1',
    url: '/store',
    query: {
      'platform[]': ['PSN'],
    },
  },
  {
    title: 'Nintendo',
    iconUrl: '/images/platform-logo-full/nintendo-logo-full.svg',
    bgColor: '#DF0000',
    url: '/store/platform/nintendo',
    query: {},
  },

  {
    title: 'Epic Games',
    iconUrl: '/images/platform-logo-full/epic-games-logo.svg',
    bgColor:
      'radial-gradient(236.06% 236.06% at 49.77% 100%, #231E9C 0%, #050412 100%)',
    url: '/store',
    query: {
      'platform[]': ['Epic Games'],
    },
  },
  {
    title: 'gog.com',
    iconUrl: '/images/platform-logo-full/gog-logo-full.svg',
    bgColor: '#4E21BE',
    url: '/store',
    query: {
      'platform[]': ['GOG COM'],
    },
  },
  {
    title: 'Rockstar Games',
    iconUrl: '/images/platform-logo-full/rockstar-logo-full.svg',
    bgColor:
      'linear-gradient(77.76deg, #000000 -5.75%, #100D46 17.78%, #4253A2 37.39%, #9A75B9 55.12%, #80275F 71.3%, #000000 96.98%)',
    url: '/store',
    query: {
      'platform[]': ['Rockstar Games'],
    },
  },
];
