import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  items: any;
  itemWidth: number;
  initialSpeed: number;
}

function useHomePageSlidingAnimation({
  items,
  itemWidth,
  initialSpeed,
}: Props) {
  const carouselRef = useRef<any>(null);
  const speedRef = useRef(initialSpeed);
  const positionRef = useRef(0);
  const lastTimestampRef = useRef(0);
  const requestRef = useRef<number | null>(null);
  const [recursiveArray, setRecursiveArray] = useState<any>(items);
  const [temp, setTemp] = useState<boolean | undefined>(undefined);
  const [temp2, setTemp2] = useState<boolean | undefined>(undefined);
  const handleCarouselRef = useCallback(() => {
    carouselRef.current = null;
    speedRef.current = initialSpeed;
    positionRef.current = 0;
    lastTimestampRef.current = 0;
  }, [initialSpeed]);

  useEffect(() => {
    if (items) {
      const temp = [...items, ...items, ...items];
      setRecursiveArray(temp);
    }
  }, [items]);

  useEffect(() => {
    if (temp) {
      const tempArray = [...recursiveArray.slice(items.length), ...items];
      setRecursiveArray(tempArray);
    }
  }, [temp, recursiveArray, items]);

  useEffect(() => {
    if (temp) {
      handleCarouselRef();
    }
  }, [temp, handleCarouselRef]);

  useEffect(() => {
    if (temp2) {
      handleCarouselRef();
    }
  }, [temp2, handleCarouselRef]);

  useEffect(() => {
    const carousel = carouselRef.current;
    const itemCount = items.length;

    // Clean up any existing clones before creating new ones
    // clonedElementsRef.current.forEach((element) => {
    //   carousel?.removeChild(element);
    // });
    // clonedElementsRef.current = [];

    // Clone the elements
    // for (let i = 0; i < itemCount; i++) {
    //   const originalItem = carousel?.children[i % itemsCount] as HTMLDivElement;
    //   if (originalItem) {
    //     const clone = originalItem.cloneNode(true) as HTMLDivElement;
    //     carousel?.appendChild(clone);
    //     clonedElementsRef.current.push(clone);
    //   }
    // }

    const animate = (timestamp: number) => {
      if (!lastTimestampRef.current) lastTimestampRef.current = timestamp;

      const elapsed = timestamp - lastTimestampRef.current;
      lastTimestampRef.current = timestamp;

      positionRef.current -= speedRef.current * (elapsed / 16.66);

      if (positionRef.current <= -itemWidth * itemCount) {
        positionRef.current += itemWidth * itemCount;
      }

      if (carousel) {
        carousel.style.transform = `translateX(${positionRef.current}px)`;
      }

      requestRef.current = requestAnimationFrame(animate);
    };

    const startAnimation = () => {
      if (requestRef.current === null) {
        lastTimestampRef.current = performance.now();
        requestRef.current = requestAnimationFrame(animate);
      }
    };

    const stopAnimation = () => {
      if (requestRef.current !== null) {
        cancelAnimationFrame(requestRef.current);
        requestRef.current = null;
      }
    };

    startAnimation();

    const handleMouseOver = () => {
      speedRef.current = initialSpeed / 3;
    };

    const handleMouseOut = () => {
      speedRef.current = initialSpeed;
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopAnimation();
      } else {
        startAnimation();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    carousel?.addEventListener('mouseover', handleMouseOver);
    carousel?.addEventListener('mouseout', handleMouseOut);

    return () => {
      stopAnimation();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      carousel?.removeEventListener('mouseover', handleMouseOver);
      carousel?.removeEventListener('mouseout', handleMouseOut);
    };
  }, [items, itemWidth, initialSpeed]);

  return { carouselRef, setTemp, temp, recursiveArray, setTemp2 };
}

export default useHomePageSlidingAnimation;
