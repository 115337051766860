import Link from 'next/link';
import { memo } from 'react';
import { PlatformCardTile } from '../HomePageV1/HomePageV1Style';
import { BackgroundIcon } from '../Icons/Icons';

interface Props {
  data: any;
}

function PlatformTile({ data }: Props) {
  return (
    <Link
      href={{
        pathname: `${data.url}`,
        query: data.query,
      }}
      passHref
      prefetch={false}
    >
      <a
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <PlatformCardTile bgColor={data.bgColor}>
          <BackgroundIcon
            url={data.iconUrl}
            height="50px"
            width="60%"
            selected
            color="#fff"
            margin="0"
          />
        </PlatformCardTile>
      </a>
    </Link>
  );
}

export default memo(PlatformTile);
