import styled from 'styled-components';

export const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const Carousel = styled.div`
  display: flex;
  gap: 16px;
  width: max-content;
`;

export const CarousalItem = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
