import { InView, useInView } from 'react-intersection-observer';
import PlatformTile from '../../../PlatformTile/PlatformTile';
import {
  CarousalItem,
  Carousel,
  CarouselContainer,
} from '../HomePageAnimations/HomePageAnimationsStyle';
import useHomePageSlidingAnimation from '../HomePageAnimations/useHomePageSlidingAnimation';

const AnimatedPlatformCards = ({ items, itemWidth, initialSpeed }: any) => {
  const { carouselRef, recursiveArray, setTemp, setTemp2 } =
    useHomePageSlidingAnimation({
      items,
      itemWidth,
      initialSpeed,
    });
  const currentRef = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <>
      <InView as="div" onChange={(inView, entry) => setTemp2(inView)}>
        <CarouselContainer>
          <Carousel ref={carouselRef}>
            {recursiveArray?.map((_: any, index: any) =>
              index === recursiveArray.length - 1 ? (
                <InView
                  as="div"
                  onChange={(inView, entry) => setTemp(inView)}
                  key={index}
                >
                  <CarousalItem>
                    <PlatformTile data={_} />
                  </CarousalItem>
                </InView>
              ) : (
                <>
                  <CarousalItem key={index}>
                    <PlatformTile data={_} key={index} />
                  </CarousalItem>
                </>
              )
            )}
          </Carousel>
        </CarouselContainer>
      </InView>
    </>
  );
};

export default AnimatedPlatformCards;
