import { Skeleton } from '@mui/material';
import Image from 'next/image';
import { useTheme } from 'styled-components';
import { HeroGridOverlay } from '../HeroGrid.styles';
import dynamic from 'next/dynamic';

export interface TileImgaeProps {
  image: string;
  link: string;
  title:string;
}

const Link = dynamic(() => import('next/link'), {
  ssr: true,
});

export const TileImage = ({ image, link,title }: TileImgaeProps) => {
  const theme = useTheme();
  return (
    <>
      {!!image ? (
        <Link href={link} prefetch={false} passHref>
          <a>
            <Image
              src={image}
              alt={title}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              quality={50}
              priority={true}
            />
            <HeroGridOverlay />
          </a>
        </Link>
      ) : (
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: '8px',
            backgroundColor: theme.palette.background.bg3,
          }}
          animation="wave"
          height="100%"
          width="100%"
        />
      )}
      
    </>
  );
};
