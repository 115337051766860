import Head from 'next/head';
import SiteLayout from '../Layout/SiteLayout';

// import useSWR from 'swr';
import axios from 'axios';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {
  BannerImageAPIInterface,
  HomePageMainCollectionInterface,
  TileImageAPIInterface,
} from '../Interface/HomepageCollectionsInterface';
import HomePageV1 from '../components/HomePageV1/HomePageV1';
import { HomePageV1Provider } from '../components/HomePageV1/context/HomePageV1Context';
import { apiConstants } from '../constants/apiConstants';
import { useAnalytics } from '../contexts/AnalyticsProvider';
import { ssrCommonMessages } from '../localization/ssr/common';
import { ssrHomeMessages } from '../localization/ssr/home';
import { getIPAddressServerSide, getUserInfoSSR } from '../utils/cf';
import { getSelectedLocaleForSSR } from '../utils/getSelectedLocaleForSSR';
import { WE_USER_HOME_PAGE_VIEWED } from '../utils/we';

interface Props {
  meta: string;
  bannerImages: BannerImageAPIInterface | null;
  tileImages: TileImageAPIInterface | null;
  mainCollection: HomePageMainCollectionInterface;
  // miniCollection: HomePageMiniCollectionInterface;
}

const Home = ({
  meta,
  bannerImages,
  tileImages,
  mainCollection,
}: // miniCollection,
Props) => {
  const { cleverTap } = useAnalytics();
  const router = useRouter();

  useEffect(() => {
    if (!cleverTap) return;
    WE_USER_HOME_PAGE_VIEWED(cleverTap);
  }, [cleverTap]);

  return (
    <>
      <Head>
        <title>Driffle: Buy Digital Goods Cheaper!</title>
        <meta httpEquiv="last-modified" content={new Date().toDateString()} />
        {/* <meta
          name="description"
          content="Level up with the best deals on games, gift cards, subscriptions, and more with Driffle. Enjoy instant delivery, lowest prices, and endless gaming fun!"
        /> */}
        <meta
          name="description"
          content="Level up with the best deals on games, gift cards, subscriptions, and more with Driffle. Enjoy instant delivery, lowest prices, and endless gaming fun!"
        />
        <meta name="robots" content="index,follow" />

        {/* <link
          rel="canonical"
          href={
            router.locale === 'en'
              ? `https://driffle.com`
              : `https://driffle.com/${router.locale}`
          }
        /> */}
        {/* <link rel="alternate" href="https://driffle.com" hrefLang="x-default" /> */}

        {/* OG Meta */}
        <meta
          property="og:title"
          content="Driffle: Buy Digital Goods Cheaper!"
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:description"
          content="Level up with the best deals on games, gift cards, subscriptions, and more with Driffle. Enjoy instant delivery, lowest prices, and endless gaming fun!"
        />
        <meta property="og:url" content="https://driffle.com" />
        <meta property="og:site_name" content="Driffle" />
        <meta
          property="og:image"
          content="https://static.driffle.com/site-assets/logo-white.png"
        />
        <meta property="fb:app_id" content="1787660858154522" />
        {/* Twitter Meta */}
        <meta
          property="twitter:title"
          content="Driffle: Buy Digital Goods Cheaper!"
        />
        <meta
          property="twitter:description"
          content="Level up with the best deals on games, gift cards, subscriptions, and more with Driffle. Enjoy instant delivery, lowest prices, and endless gaming fun!"
        />
        <meta property="twitter:url" content="https://driffle.com" />
        <meta
          property="twitter:image"
          content="https://static.driffle.com/site-assets/logo-white.png"
        />
        <meta name="twitter:card" content="summary" />
        <script
          id="json-ld-product"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@graph': [
                {
                  '@context': 'https://schema.org/',
                  '@type': 'Website',
                  name: 'Driffle Inc',
                  url: 'https://driffle.com/',
                  logo: 'https://driffle.com/logo/driffle-logo-white.svg',
                  description:
                    'Driffle is a digital goods marketplace specializing in digital gaming products, including games, gift cards, DLCs, and more. We offer a convenient and diverse selection, from the newest releases to timeless classics, all in one place.',
                  sameAs: [
                    'https://www.linkedin.com/company/driffle/',
                    'https://www.facebook.com/drifflecom/',
                    'https://www.instagram.com/drifflecom/',
                    'https://discord.gg/MryXrnwZvz',
                    'https://www.youtube.com/@drifflecom',
                  ],
                  potentialAction: [
                    {
                      '@type': 'SearchAction',
                      target:
                        'https://www.driffle.com/search?q={search_term_string}',
                      'query-input': 'required name=search_term_string',
                    },
                  ],
                },
              ],
            }),
          }}
        />
        {/* <link
          rel="preload"
          href={bannerImages?.desktop[0]?.imageUrl}
          as="image"
        />
        <link
          rel="preload"
          href={bannerImages?.mobile[0]?.imageUrl}
          as="image"
        /> */}
      </Head>

      <SiteLayout>
        <HomePageV1Provider
          mainCollection={mainCollection}
          bannerImages={bannerImages}
          tileImages={tileImages}
          // miniCollection={miniCollection}
        >
          <HomePageV1 />
        </HomePageV1Provider>
      </SiteLayout>
    </>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const localeData = await import(
    `../localization/languages/${context?.locale ?? 'en'}.ts`
  );

  const ssrMessages = getSelectedLocaleForSSR(localeData.default, {
    ...ssrHomeMessages,
    ...ssrCommonMessages,
  });

  const newHeaders: any = {};

  let userIp = getIPAddressServerSide(context.req);
  if (userIp) {
    newHeaders['x-ip-address'] = userIp;
  }

  const userInfo = await getUserInfoSSR(context.req, context.res);

  const REGION = process.env.REGION ?? '';

  const apis = [
    axios.get(
      apiConstants.ssrServer.server.replace('[--REGION--]', `${REGION}`) +
        '/public/banners/v5',
      {
        withCredentials: true,
        headers: newHeaders,
      }
    ),
    axios.get(
      apiConstants.ssrServer.server.replace('[--REGION--]', `${REGION}`) +
        '/public/collections/homepage/main',
      {
        withCredentials: true,
        headers: newHeaders,
      }
    ),
  ];

  try {
    const response: any = await Promise.all(apis);

    const bannerImages = response[0].data.data;
    const tileImages = response[0].data.data.tiles;
    const mainCollection = response[1].data.data;

    return {
      props: {
        locale: context?.locale ?? 'en',
        localeData: ssrMessages,
        reduxInitialState: userInfo,
        bannerImages,
        tileImages,
        mainCollection,
      },
    };
  } catch (error) {
    return {
      notFound: true,
    };
  }
};
