import { useRouter } from 'next/router';
import { memo } from 'react';
import { useTheme } from 'styled-components';
import { saveEventV3 } from '../../../utils/eventTracking';
import { HeroGridTile2 } from '../HeroGrid.styles';
import { TileProps } from './HeroGridTile1';
import { TileImage } from './TileImage';
const HeroGridTile2Comp = ({ data }: TileProps) => {
  const theme = useTheme();
  const router = useRouter();
  return (
    <HeroGridTile2
      onClick={() => {
        if (data)
          saveEventV3({
            category: 'banner',
            action: 'click',
            label: 'tile-2',
            properties: 'grid_banner',
            value: [data.title],
            from: router,
          });
      }}
    >
      <TileImage
        image={data?.imageUrl || ''}
        link={data?.link || ''}
        title={data?.title || 'tile'}
      />
    </HeroGridTile2>
  );
};

export default memo(HeroGridTile2Comp);
