import { homePagePlatformsData } from '../../../../constants/platforms';
import { WIDTH } from '../../../../constants/screenResolution';
import { useLocale } from '../../../../contexts/LocalizationContext';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../../../utils/getNumberFromResolutionConstant';
import PlatformTile from '../../../PlatformTile/PlatformTile';
import {
  CategoryCardContainerAnimation,
  ProductSectionWrapper,
  SectionTitle,
  SectionTitleContainer,
  SmallCardSectionContainer,
} from '../../HomePageV1Style';
import AnimatedPlatformCards from './AnimatedPlatformCards';

function PlatformsSection() {
  const { messages } = useLocale();
  const { explore_by_platforms_msg } = messages || {};
  const { width } = useWindowDimensions();
  return (
    <ProductSectionWrapper animationTrue={true}>
      <SectionTitleContainer>
        <SectionTitle>{explore_by_platforms_msg}</SectionTitle>
      </SectionTitleContainer>
      <SmallCardSectionContainer>
        <CategoryCardContainerAnimation>
          {width && width > getNumFromResolutionConstant(WIDTH.tabletMax) ? (
            <AnimatedPlatformCards
              items={homePagePlatformsData}
              itemWidth={'220px'}
              initialSpeed={0.5}
            />
          ) : (
            homePagePlatformsData.map((_, index) => (
              <PlatformTile data={_} key={index} />
            ))
          )}
        </CategoryCardContainerAnimation>
      </SmallCardSectionContainer>
    </ProductSectionWrapper>
  );
}

export default PlatformsSection;
