import { Tooltip } from '@mui/material';
import { memo, ReactElement } from 'react';
import { WIDTH } from '../../constants/screenResolution';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';

interface Props {
  children: ReactElement;
  title: ReactElement | null;
  noPadding?: boolean;
  displayStatus?: boolean;
  showArrow?: boolean;
  placement?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'left-start'
    | 'left-end'
    | 'right-start'
    | 'right-end';
}

function TooltipWithArrow({
  children,
  title,
  noPadding,
  displayStatus = true,
  showArrow = true,
  placement,
}: Props) {
  const { width } = useWindowDimensions();

  return displayStatus ? (
    !title ? (
      <>{children}</>
    ) : (
      <Tooltip
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={2500}
        placement={
          width && width > getNumFromResolutionConstant(WIDTH.mobileMax)
            ? placement || 'top'
            : 'bottom-end'
        }
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              width: 'fit-content',
              fontSize: '12px',
              padding: noPadding ? '0' : '6px 12px',
              margin: 'auto',
              border: '1px solid #353535',
              borderRadius: '12px',
              background: '#212121',
              pointerEvents: 'auto', // Enable interaction with the tooltip content
              userSelect: 'text', // Enable text selection for copying
            },
            '& .MuiTooltip-arrow': {
              '&::before': {
                display: showArrow ? 'block' : 'none',
                background: '#212121',
                border: '1px solid #353535',
              },
            },
          },
        }}
        title={
          <span
            onClick={(e) => e.stopPropagation()}
            style={{
              font: '.875rem/1.125rem Onest-Medium',
            }}
          >
            {title}
          </span>
        } // Stop propagation to prevent clicks on the product card
      >
        <div style={{ pointerEvents: 'none' }}>
          <div style={{ pointerEvents: 'auto' }}>{children}</div>
        </div>
      </Tooltip>
    )
  ) : (
    children
  );
}

export default memo(TooltipWithArrow);
