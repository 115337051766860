import { memo, useContext } from 'react';

import dynamic from 'next/dynamic';
import HeroGrid from '../../../HeroGrid/HeroGrid';
import MobileExploreSection from '../../../MobileExploreSection/MobileExploreSection';
import { HomePageV1Context } from '../../context/HomePageV1Context';
import { InvisibleBoxSpacing } from '../../HomePageV1Style';
import Section0 from '../sections/Section0';
import Section1 from '../sections/Section1';

const Section2 = dynamic(() => import('../sections/Section2'), {
  ssr: false,
});
const Section3 = dynamic(() => import('../sections/Section3'), {
  ssr: false,
});
const Section4 = dynamic(() => import('../sections/Section4'), {
  ssr: false,
});

function DesktopPage() {
  const {
    bannerImages,
    tileImages,
    section0Ref,
    section1Ref,
    section2Ref,
    section3Ref,
    section4Ref,
    section5Ref,
  } = useContext(HomePageV1Context);

  return (
    <>
      <MobileExploreSection title="Explore" />

      {/* <NewHeroCarousel bannerImages={bannerImages} /> */}
      <HeroGrid bannerImages={bannerImages} tileImages={tileImages} />

      <InvisibleBoxSpacing />

      {/* <Section0 /> */}
      <div ref={section0Ref.ref}>
        <div id="jewel-carousel_1" />
        <Section0 />
      </div>

      {/* // section 1 */}
      <div ref={section1Ref.ref}>
        <div id="jewel-carousel_2" />
        <Section1 />
      </div>

      {/* // section 2 */}
      <div ref={section2Ref.ref}>
        <div id="jewel-carousel_3" />

        <Section2 />
      </div>

      {/* // section 3 */}
      <div ref={section3Ref.ref}>
        <div id="jewel-carousel_4" />

        <Section3 />
      </div>

      {/* // section 4 */}
      <div ref={section4Ref.ref}>
        <div id="jewel-carousel_5" />
        <Section4 />
      </div>

      {/* // section 5 */}
      {/* <div ref={section5Ref.ref}>
        <Section5 />
      </div> */}
    </>
  );
}

export default memo(DesktopPage);
