import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { useTheme } from 'styled-components';
import usePlusMembershipFeatures from '../../../../hooks/localeConstants/usePlusMembershipFeatures';
import { useAppSelector } from '../../../../redux/hooks';
import { saveEventV3 } from '../../../../utils/eventTracking';
import {
  buyMembership,
  membershipCategories,
  membershipTypes,
} from '../../../../utils/membership';
import { BackgroundIcon } from '../../../Icons/Icons';
import Button from '../../../NewButton/Button';
import {
  DrifflePlusSellCardBodyList,
  DrifflePlusSellCardBodyListItem,
  DrifflePlusSellCardCaption,
  DrifflePlusSellCardContainer,
  DrifflePlusSellCardJoinNowButton,
  DrifflePlusSellCardLogoImage,
  DrifflePlusSellCardWrapper,
  LearnMoreText,
} from '../LandingPageStyle';

function DrifflePlusSellCard() {
  const user = useAppSelector((state) => state.user);
  const router = useRouter();
  const theme = useTheme();
  const { features } = usePlusMembershipFeatures();

  const handleJoinNow = async (membership: membershipTypes) => {
    if (user.subscription === 'active') {
      router.push('/user/membership');
    } else {
      try {
        saveEventV3({
          category: 'plus_banner',
          label: 'buy_now_monthly_plus',
          action: 'click',
          properties: '',
          value: [],
          from: router,
        });
        await buyMembership(membership);
        router.push('/plus/checkout');
      } catch (error) {}
    }
  };

  return (
    <DrifflePlusSellCardWrapper>
      <DrifflePlusSellCardContainer>
        <DrifflePlusSellCardLogoImage>
          <Image
            src={`/logo/driffle-plus-${
              theme.mode === 'dark' ? 'dark' : 'light'
            }-logo.svg`}
            alt="Driffle logo"
            priority={true}
            layout="fill"
            objectFit="cover"
            unoptimized
          />
        </DrifflePlusSellCardLogoImage>
        <DrifflePlusSellCardCaption>
          Cheapest game keys and premium services
        </DrifflePlusSellCardCaption>
        <DrifflePlusSellCardBodyList>
          {features.map((feature, index) => (
            <DrifflePlusSellCardBodyListItem key={index}>
              <div>
                <BackgroundIcon
                  url={`/icons/${
                    theme.mode === 'dark' ? 'plus-white-tick' : 'purple-tick-24'
                  }.svg`}
                  height="20px"
                  width="20px"
                  margin="0"
                />
              </div>
              <p>{feature}</p>
            </DrifflePlusSellCardBodyListItem>
          ))}
        </DrifflePlusSellCardBodyList>

        {user.subscription === 'inactive' && (
          <>
            <DrifflePlusSellCardJoinNowButton>
              <Button onClick={() => handleJoinNow('monthly')}>
                Buy now - €{membershipCategories.monthly.price} monthly
              </Button>
            </DrifflePlusSellCardJoinNowButton>

            <LearnMoreText>
              <Link href={'/plus'} prefetch={false} passHref>
                <a
                  onClick={() => {
                    saveEventV3({
                      category: 'plus_banner',
                      label: 'learn_more',
                      action: 'click',
                      properties: '',
                      value: [],
                      from: router,
                    });
                  }}
                >
                  Learn more
                </a>
              </Link>
            </LearnMoreText>
          </>
        )}
      </DrifflePlusSellCardContainer>
    </DrifflePlusSellCardWrapper>
  );
}

export default memo(DrifflePlusSellCard);
