import { useRouter } from 'next/router';
import { memo } from 'react';
import { BannerImageI } from '../../../Interface/HomepageCollectionsInterface';
import { saveEventV3 } from '../../../utils/eventTracking';
import { HeroGridTile1 } from '../HeroGrid.styles';
import { TileImage } from './TileImage';

export interface TileProps {
  data: BannerImageI | null;
}

const HeroGridTile1Comp = ({ data }: TileProps) => {
  const router = useRouter();
  return (
    <HeroGridTile1
      onClick={() => {
        if (data)
          saveEventV3({
            category: 'banner',
            action: 'click',
            label: 'tile-1',
            properties: 'grid_banner',
            value: [data.title],
            from: router,
          });
      }}
    >
      <TileImage
        image={data?.imageUrl || ''}
        link={data?.link || ''}
        title={data?.title || 'tile'}
      />
    </HeroGridTile1>
  );
};

export default memo(HeroGridTile1Comp);
